import axios from "axios";
import ApiConstants from '../constants/ApiConstants';

// interface labelForMethod {
//   get: string;
//   post: string;
//   put: string;
//   delete: string;
// }

//const authToken = "Bearer dCEL8iXvyAK-0ASRAIotXgLAM06uHiNqfDxQJ3CLsdAoMegW7iHTQnqFig4vshSa7gbm3G6JjyV3jfB05_MMs2O4V3kiRJzWADAqLUim4nc5exxqCsN652n461t-g6zfX1XSU5oE0VaV-2lDStOX0lwYiC90FsCjeIx9QxeTR86YjEldlPvKiyKg1xWettQKlE5DlpbniiTM4jzxkIKo2RX0irZxN7B0zDLWEXcsgluRmgFaSl-AR4LhEKLoZPxaTsiDqg";
//const authToken = "Bearer 9hrFvdLgW-0in17kcstp1YBqG-gXeDoHTvF9lXKRNw4_w7GQhqqdg63JA4tCs_UxcKorV9S-gDVhrDLfw3ggjCQC2uNUC71EeSRY5GyZkSKnrXB-2KRVm8pGiqk6kXYNCVOwGl6BS43J4M61NqbQh4XOEFDMH-eQ3hgazxDOg-hA8IM-jPFGsqZMFhyxXN8P31PGqiu39nW-_yZKp750N1nxskTfzl0CPzNCQChMy_7f8Ykt4KLzhuxl0a6VM89dv7P-4Q";
//let baseUrl = "";
let servicesBaseUrl = ApiConstants.qaServicePath;
// if (process.env.REACT_APP_STAGE === "dev" || process.env.REACT_APP_STAGE === "qa") {
//   //baseUrl = apiConstants.;
//   servicesBaseUrl = apiConstants.qaServicePath;
// }
// else {
//   //baseUrl = ApiConstants.prodBaseUrl;
//   servicesBaseUrl = apiConstants.prodServicePath;
// }
// const client = axios.create({
//   baseURL: baseUrl
// });
const client = axios.create({
  baseURL: servicesBaseUrl
});

// const methodName: labelForMethod = {
//   get: "FETCH",
//   post: "CREATE",
//   put: "UPDATE",
//   delete: "DELETE"
// };
// export const getActionName = (FROM_WHERE: string, method: string): string => {
//   return `${FROM_WHERE}_${methodName[method]}`;
// };

export const request = (options) => {
  let requestOptions = options;
//   let apiSource = "";
  let headers = {};

  switch (options.method) {
      case "get":
        const {...restParams } = options.params;
        requestOptions = { ...requestOptions, params: restParams };
        break;
      default:
        const {...restData } = options.data;
        requestOptions = { ...requestOptions, data: restData };
        break;
  }

//   if (options.method === "get") {
//     const {...restParams } = options.params;
//     requestOptions = { ...requestOptions, params: restParams };
//     //apiSource = FROM_WHERE;
//   } else if (options.method === "post" || options.method === "put" || options.method === "delete") {
//     const {...restData } = options.data;
//     requestOptions = { ...requestOptions, data: restData };
//     //apiSource = FROM_WHERE;
//   }
//   if (SessionConstants.userData.token && (!requestOptions.url.includes("Token"))) {
//     headers = { ...headers, Authorization: SessionConstants.userData.token };
//   }
//   if (apiSource) {
//     headers = {
//       ...headers,
//       APISource: getActionName(apiSource, requestOptions.method)
//     };
//   }
return client({ ...requestOptions, headers })
.then(response => response)
.catch(error => {
  return Promise.reject(error.response || error.message);
});
}

//   if (requestOptions.url.includes("Token")) {
//     headers = {
//       ...headers, "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
//     }
//     // let data = `grant_type=${ApiConstants.loadTokenGrantType}&username=${SessionConstants.userData.memberId}|${SessionConstants.userData.resellerId}`;
//     const { data = "" } = options.data;
//     requestOptions = { ...requestOptions, data: data }
//     return servicesClient({ ...requestOptions, headers })
//       .then(response => response)
//       .catch(error => {
//         if (!requestOptions.url.includes("apiexceptionlog")) {
//           logException({
//             action: getActionName(apiSource, requestOptions.method),
//             errorMessage: error.response.data.Message || error.message
//           });
//         }
//         return Promise.reject(error.response || error.message);
//       })
//   }
//   else {
//     return client({ ...requestOptions, headers })
//       .then(response => response)
//       .catch(error => {
//         if (!requestOptions.url.includes("apiexceptionlog")) {
//           logException({
//             action: getActionName(apiSource, requestOptions.method),
//             errorMessage: error.response.data.Message || error.message
//           });
//         }
//         return Promise.reject(error.response || error.message);
//       });
//   }
// };

// export const logException = (exceptionData: any) => {
//   request({
//     method: "post",
//     url: `master/apiexceptionlog`,
//     data: {
//       ApiExceptionLog: {
//         apisource: exceptionData.action,
//         exception: exceptionData.errorMessage,
//         exceptiontype: "CLIENT"
//       }
//     }
//   });
// };
