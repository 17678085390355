/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
import MaterialTable from 'material-table'
import Swal from 'sweetalert2';
// import swal from 'sweetalert'
import { getReferedPatientList } from "../service/PatientService";
// import { getDoctorList } from '../../Doctor/service/DoctorService';
import { useSelector } from "react-redux";
//import { PopupWithDropDown } from "../../../common/components/PopupWithDropDown";

const localStorageActiveTabKey = "appointmentActiveTab";

// const patientRefereToDoctorData = {
//     ref_by_id: "",
//     patient_id: "",
//     ref_to_id: ""
// };

export function PatientReferedList() {
    const activeTab = localStorage.getItem("authToken");
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    // const { user } = useSelector(state => state.auth);
    // patientRefereToDoctorData.ref_by_id = user.user_id;
    const enableLoading = () => {
        setLoading(true);
    };
    const disableLoading = () => {
        setLoading(false);
    };

    // const referPatient = (event, rowdata) => {
    //     getDoctorList()
    //         .then(
    //             (response) => {
    //                 let patientDDL = {};
    //                 if (response.data.status == 200) {
    //                     response.data.data.map((item) => { patientDDL[item['id']] = item['name'] });
    //                     Swal.fire({
    //                         title: "Doctor to be refered",
    //                         input: "select",
    //                         inputOptions: patientDDL,
    //                         inputPlaceholder: "Select Doctor",
    //                         showCancelButton: true,
    //                         inputValidator: function (value) {
    //                             return new Promise(function (resolve, reject) {
    //                                 if (value !== '') {
    //                                     resolve();
    //                                 } else {
    //                                     resolve('You need to select a Doctor');
    //                                 }
    //                             });
    //                         }
    //                     }
    //                     )
    //                         .then(
    //                             (result) => {
    //                                 patientRefereToDoctorData.patient_id = rowdata.id;
    //                                 patientRefereToDoctorData.ref_to_id = result.value;
    //                                 postPatientToDoctor(patientRefereToDoctorData)
    //                                     .then(
    //                                         (response) => {
    //                                             if (response.data.status == 200) {
    //                                                 swal({
    //                                                     title: "Done!",
    //                                                     text: response.data.message,
    //                                                     icon: "success",
    //                                                     button: "Ok!",
    //                                                 });
    //                                             }
    //                                             else if (response.data.status == 400) {
    //                                                 Swal.fire({
    //                                                     title: "Error!",
    //                                                     text: response.data.message,
    //                                                     icon: "warning",
    //                                                     button: "Ok!",
    //                                                     dangerMode: true,
    //                                                 });
    //                                             }
    //                                         }
    //                                     ).catch(
    //                                         (error) => {
    //                                             console.log(error)
    //                                         }
    //                                     );
    //                             }
    //                         );
    //                 }
    //                 else if (response.data.status == 400) {
    //                     Swal.fire({
    //                         title: "Error!",
    //                         text: response.data.message,
    //                         icon: "warning",
    //                         button: "Ok!",
    //                         dangerMode: true,
    //                     });
    //                 }
    //                 disableLoading();
    //             }
    //         )
    //         .catch(
    //             (error) => {
    //                 console.log(error)
    //             }
    //         );
    // };

    const [state, setState] = React.useState({
        columns: [
            { title: 'Refered Doctor', field: 'name' },
            { title: 'Patient Name', field: 'patient_name' },
            { title: 'Email', field: 'email' },
            { title: 'Mobile Number', field: 'phone' },
            { title: 'Gender', field: 'gender' },
            { title: 'Blood Group', field: 'bloodgroup' }
        ],
        data: [
        ]
    });
    useEffect(() => {
        getReferedPatientList()
            .then(
                (response) => {
                    if (response.data.status == 200) {
                        setState(prevState => ({ ...prevState, data: response.data.data }));
                    }
                    else if (response.data.status == 400) {
                        Swal.fire({
                            title: "Error!",
                            text: response.data.message,
                            icon: "warning",
                            button: "Ok!",
                            dangerMode: true
                        });
                    }
                    disableLoading();
                }
            )
            .catch(
                (error) => {
                    console.log(error)
                }
            );
    }, [page]);

    return (
        <MaterialTable
            title="Patient List"
            columns={state.columns}
            data={state.data}
        />
    );
}
