import CentralService from '../../../common/service/CentralService';
import ApiConstants from '../../../common/constants/ApiConstants';

const centralService = new CentralService();

export function postDoctorRegistration(data){
    return centralService.doPost(ApiConstants.registerDoctorPath,data);
}

export function getDoctorList(){
    return centralService.doGet(ApiConstants.loadDoctorListPath);
}
