/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { useFormik } from "formik";
import swal from 'sweetalert';
import * as Yup from "yup";
import { postDoctorRegistration } from '../service/DoctorService';

const initialValues = {
    username: "",
    email: "",
    address: "",
    phone: "",
    specialization: "",
    qualification: "",
    focus_area: ""
};

export const DoctorRegistration = (props) => {
    const [loading, setLoading] = useState(false);

    const RegistrationSchema = Yup.object().shape({
        username: Yup.string()
            .required("Name is mandatory"),
        email: Yup.string()
            .email("Enter valid email")
            .required("Email is mandatory"),
        address: Yup.string()
            .required("Address is mandatory"),
        phone: Yup.string()
            .required("Mobile is mandatory"),
        specialization: Yup.string()
            .required("Specialization is mandatory"),
        qualification: Yup.string()
            .required("Qualification is mandatory"),
        focus_area: Yup.string()
            .required("Focus Area is mandatory")
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values,{setSubmitting}) => {
            /*Register API*/
            enableLoading();
            postDoctorRegistration(values)
                .then(
                    (response) => {
                        if (response.data.status === 200) {
                            swal({
                                title: "Done!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            });
                            props.history.push('/auth/login');
                        }
                        else if (response.data.status === 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                    }
                )
                .catch(
                    (error) => {
                        disableLoading();
                        setSubmitting(false);
                        console.log(error)
                    }
                );
        },
        onReset: () => {
            formik.values = initialValues;
        }
    });

    return (
        <div className="form">
            <form 
            action="" 
            method="POST" 
            autoComplete="off" 
            onSubmit={formik.handleSubmit} 
            onReset={formik.handleReset} 
            name="doctorRegistrationForm" 
            id="doctorRegistrationForm" 
            >
                <div className="tab-content pt-3">
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                            Name:
                            </label>
                        <div className="col-lg-9 col-xl-34">
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                id="username"
                                maxLength="255"
                                placeholder="Enter Full Name"
                                value={formik.values.username}
                                {...formik.getFieldProps("username")}
                            />
                            {formik.touched.username && formik.errors.username ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.username}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                            Email:
                            </label>
                        <div className="col-lg-9 col-xl-34">
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                id="email"
                                maxLength="255"
                                placeholder="Enter Email"
                                value={formik.values.email}
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.email}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                            Address:
                            </label>
                        <div className="col-lg-9 col-xl-34">
                            <textarea
                                type="text"
                                className="form-control"
                                name="address"
                                id="address"
                                maxLength="255"
                                placeholder="Enter Complete Address"
                                value={formik.values.address}
                                {...formik.getFieldProps("address")}
                            />
                            {formik.touched.address && formik.errors.address ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.address}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                            Mobile No:
                            </label>
                        <div className="col-lg-9 col-xl-34">
                            <input
                                type="text"
                                className="form-control"
                                name="phone"
                                id="phone"
                                maxLength="10"
                                placeholder="Enter Mobile No."
                                value={formik.values.phone}
                                {...formik.getFieldProps("phone")}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.phone}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                            Specialization:
                            </label>
                        <div className="col-lg-9 col-xl-34">
                            <input
                                type="text"
                                className="form-control"
                                name="specialization"
                                id="specialization"
                                maxLength="255"
                                placeholder="Enter Specialization Details"
                                value={formik.values.specialization}
                                {...formik.getFieldProps("specialization")}
                            />
                            {formik.touched.specialization && formik.errors.specialization ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.specialization}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                            Qualification:
                            </label>
                        <div className="col-lg-9 col-xl-34">
                            <input
                                type="text"
                                className="form-control"
                                name="qualification"
                                id="qualification"
                                maxLength="255"
                                placeholder="Enter Qualification Details"
                                value={formik.values.qualification}
                                {...formik.getFieldProps("qualification")}
                            />
                            {formik.touched.qualification && formik.errors.qualification ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.qualification}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                            Focus Area:
                            </label>
                        <div className="col-lg-9 col-xl-34">
                            <input
                                type="text"
                                className="form-control"
                                name="focus_area"
                                id="focus_area"
                                maxLength="255"
                                placeholder="Enter Focus Area"
                                value={formik.values.focus_area}
                                {...formik.getFieldProps("focus_area")}
                            />
                            {formik.touched.focus_area && formik.errors.focus_area ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.focus_area}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-9">
                            <button
                                type="submit"
                                className={`btn btn-primary font-weight-bold mr-2`}
                            >
                                <i className="la la-eye" /> Submit
                                            </button>
                            {" "}
                            <button
                                type="reset"
                                className={`btn btn-clean font-weight-bold mr-2`}
                            >
                                <i className="la la-recycle" /> Reset
                                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

