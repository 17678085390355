const qaDomainPath = "http://103.250.185.131";
const prodDomainPath = "http://103.250.185.131";
const basePath = "/~drapi";
export default {
    qaServicePath :   qaDomainPath + basePath,
    prodServicePath : prodDomainPath + basePath,
    registerDoctorPath : "/Authentication/registerDoctor",
    loginDoctorPath : "/Authentication/doctorLogin",
    registerPatientPath : "/Authentication/register_patient",
    loadPatientListPath : "/Authentication/getPatientList",
    loadAllPatientListPath : "/Authentication/getAllPatientList",
    loadDoctorListPath : "/Authentication/getDoctorList",
    postPatientReferToDoctorPath : "/Authentication/addReferdetail",
    loadReferedPatientList : "/Authentication/getReferedDetails",
    registerAppointmentPath : "/Authentication/add_appointment",
    loadAppointmentListPath: "/Authentication/getAppointmentList",
    loadPatientDetailsPath: "/Authentication/patientById",
    postPatientUpdatePath: "/Authentication/updatePatient",
    loadAppointmentDetailsPath: "/Authentication/appointmentlistById",
    postAppointmentUpdatePath: "/Authentication/update_appointment"
}
