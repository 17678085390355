import CentralService from '../../../common/service/CentralService';
import ApiConstants from '../../../common/constants/ApiConstants';

const centralService = new CentralService();

export function postPatientRegistration(data){
    return centralService.doPost(ApiConstants.registerPatientPath,data);
}

export function getPatientList(){
    return centralService.doGet(ApiConstants.loadPatientListPath);
}

export function getAllPatientList(){
    return centralService.doGet(ApiConstants.loadAllPatientListPath);
}

export function postPatientToDoctor(data){
    return centralService.doPost(ApiConstants.postPatientReferToDoctorPath,data);
}

export function getReferedPatientList(){
    return centralService.doGet(ApiConstants.loadReferedPatientList);
}

export function getPatientDetails(params){
    return centralService.doGet(ApiConstants.loadPatientDetailsPath + "/" + params);
}

export function postPatientUpdate(data){
    return centralService.doPost(ApiConstants.postPatientUpdatePath,data);
}