import React from "react";
import {Appointment} from "../../_metronic/_partials";
import { AppointmentBook, AppointmentList, AppointmentBookUpdate } from "../../app/modules/Appointment";

export function AppointmentPage() {
    return <Appointment />;
}

export function AppointmentBookPage() {
    return <AppointmentBook />;
}

export function AppointmentListPage() {
    return <AppointmentList />;
}

export function AppointmentBookUpdatePage() {
    return <AppointmentBookUpdate />;
}