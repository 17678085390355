import { request } from "./Request";

class CentralService {
  doGet = (url, params) => request({ method: "get", url, params });
  
  doPost = (url, data) => request({ method: "post", url, data });

  doPut = (url, data) => request({ method: "put", url, data });

  doDelete = (url, data) => request({ method: "delete", url, data });
}

export default CentralService;