import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { PatientRegistrationPage, PatientListPage, ReferedPatientListPage, PatientRegistrationUpdatePage } from "../app/pages/PatientPage";
import { AppointmentBookPage, AppointmentListPage, AppointmentBookUpdatePage } from "./pages/AppointmentPage";
import { Commision_master_Page } from "./pages/Commision_master_Page";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/patient" component={PatientRegistrationPage} />
        <ContentRoute path="/patientupdate" component={PatientRegistrationUpdatePage} />
        <ContentRoute path="/patients" component={PatientListPage} />
        <ContentRoute path="/appointment" component={AppointmentBookPage} />
        <ContentRoute path="/appointmentupdate" component={AppointmentBookUpdatePage} />
        <ContentRoute path="/appointments" component={AppointmentListPage} />
        <ContentRoute path="/referedpatients" component={ReferedPatientListPage} />
        <ContentRoute path="/commision_master" component={Commision_master_Page} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
