/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import clsx from "clsx";
import swal from 'sweetalert';
import { useHtmlClassService } from "../../../../_metronic/layout";
import * as Yup from "yup";
import { getAllPatientList } from "../../Patient/service/PatientService";
import { postAppointmentBook, postAppointmentUpdate, getAppointmentDetails } from "../service/AppointmentService";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const localStorageActiveTabKey = "appointmentActiveTab";

const initialValues = {
    patient_id: '',
    doctor_id: '',
    date: '',
    time_slot: '',
    consultType: '',
    appointmentType: '',
    amount: '',
    service_id: ''
};

export function AppointmentBook() {
    const initialValues = {
        patient_id: '',
        doctor_id: '',
        date: '',
        time_slot: '',
        consultType: '',
        appointmentType: '',
        amount: '',
        service_id: ''
    };

    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const htmlClassService = useHtmlClassService();
    const { user } = useSelector(state => state.auth);
    initialValues.doctor_id = user.user_id;

    let history = useHistory();

    const AppointmentSchema = Yup.object().shape({
        patient_id: Yup.string()
            .required("Select Patient Name "),
        date: Yup.string()
            .required("Date is mandatory"),
        time_slot: Yup.string()
            .required("Time Slot is mandatory"),
        consultType: Yup.string()
            .required("Consult Type is mandatory"),
        appointmentType: Yup.string()
            .required("Appointment Type is mandatory"),
        amount: Yup.string()
            .required("Amount is mandatory"),
        service_id: Yup.string()
            .required("Service is mandatory")
    });

    const saveCurrentTab = (_tab) => {
        localStorage.setItem(localStorageActiveTabKey, _tab);
    }

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const [state, setState] = React.useState({
        data: [
        ],
        action: "CREATE"
    });

    const [page, setPage] = useState(1);
    useEffect(() => {
        /*Get doctor list*/
        getAllPatientList()
            .then(response => {
                if (response.data.status == 200) {
                    setState(prevState => ({ ...prevState, data: response.data.data }));
                }
                else if (response.data.status == 400) {
                    this.setState({ doctor_list: response.data.data })
                    console.log('something error occured');
                }
            });

        /*Get Appointment details*/
        if (history.location.state != null && history.location.state.id > 0) {
            getAppointmentDetails(history.location.state.id)
                .then(
                    (response) => {
                       initialValues.patient_id = response.data.data.patient_id;
                       initialValues.date = response.data.data.date;
                       initialValues.time_slot = response.data.data.time_slot;
                       initialValues.consultType = response.data.data.consultType;
                       initialValues.appointmentType = response.data.data.appointmentType;
                       initialValues.amount = response.data.data.amountPaid;
                       initialValues.service_id = response.data.data.service_id;
                       initialValues['apt_id'] = history.location.state.id;  
                    setState(prevState => ({ ...prevState, action: "UPDATE" }));
                    }
                )
                .catch(
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }, [page]);

    /*get patinet list*/
    const dropDownChange = (event) => {  //debugger;
        formik.values[event.target.doctor_id] = event.target.value;
    }

    const formik = useFormik({
        initialValues,
        validationSchema: AppointmentSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            /*Register API*/
            enableLoading();
            if(state.action === "CREATE"){
                postAppointmentBook(values)
                .then(
                    (response) => {
                        if (response.data.status === 200) {
                            swal({
                                title: "Done!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            });
                            resetForm(initialValues);
                            initialValues.doctor_id = user.user_id;
                        }
                        else if (response.data.status === 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                    }
                )
                .catch(
                    (error) => {
                        console.log(error)
                    }
                );
            }else{
                postAppointmentUpdate(values)
                .then(
                    (response) => {
                        if (response.data.status === 200) {
                            swal({
                                title: "Done!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            });
                            resetForm(initialValues)
                            initialValues.doctor_id = user.user_id;
                        }
                        else if (response.data.status === 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                    }
                )
                .catch(
                    (error) => {
                        console.log(error)
                    }
                );
            }
            
        },
        onReset: () => {
            formik.values = initialValues;
        }
    });

    //patient list
    var patientList = state.data.length > 0 && state.data.map((item, i) => {
        return (
            <option key={i} value={item.id}>{item.name}</option>
        )
    });

    return (
        <>
            <div className="card card-custom">
                {/*Header*/}
                <div className="card-header card-header-tabs-line">
                    <ul
                        className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                        data-remember-tab="tab_id"
                        role="tablist"
                    >
                        <li className="nav-item">
                            <a
                                className={`nav-link ${key === 0 ? "active" : ""}`}
                                data-toggle="tab"
                                onClick={() => {
                                    setKey(0);
                                    saveCurrentTab(0);
                                }}
                            >
                                Add New Appointment (Clinic Only)
                                        </a>
                        </li>

                    </ul>
                </div>

                <div className="form">
                    <form action="" method="POST" autoComplete="off" onSubmit={formik.handleSubmit} onReset={formik.handleReset} name="bookAppointmentForm" id="bookAppointmentForm" >
                        <div className="card-body">
                            <div className="tab-content pt-3">
                                <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label text-lg-right">
                                            Patient:
                                                </label>
                                        <div className="col-lg-9 col-xl-4">
                                            {/* <input type="text" className="form-control" name="patient_id"  maxLength="50" placeholder="Enter Name" /> */}
                                            <select 
                                            className="form-control" 
                                            name="patient_id" 
                                            id="patient_id"
                                            value = {formik.values.patient_id} 
                                            onChange={dropDownChange} 
                                            {...formik.getFieldProps("patient_id")}
                                            disabled = {state.action === "CREATE" ? false : true}
                                            >
                                                <option value="">Select Patient</option>
                                                {patientList}
                                            </select>
                                            {formik.touched.patient_id && formik.errors.patient_id ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.patient_id}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label text-lg-right">
                                            Date:
                                                </label>
                                        <div className="col-lg-9 col-xl-4">
                                            <input 
                                            type="date" 
                                            className="form-control" 
                                            name="date"
                                            id="date"
                                            value = {formik.values.date}
                                            maxLength="50"
                                            placeholder="Date" 
                                            {...formik.getFieldProps("date")} />
                                            {/* <DatePicker selected={startDate} onChange={date => setStartDate(date)} /> */}
                                            {formik.touched.date && formik.errors.date ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.date}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label text-lg-right">
                                            Time Slot:
                                                </label>
                                        <div className="col-lg-9 col-xl-4">
                                            <input 
                                            type="text" 
                                            className="form-control" 
                                            name="time_slot" 
                                            id="time_slot" 
                                            value = {formik.values.time_slot}
                                            maxLength="50" 
                                            placeholder="Time Slot" 
                                            {...formik.getFieldProps("time_slot")} />

                                            {formik.touched.time_slot && formik.errors.time_slot ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.time_slot}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label text-lg-right">
                                            Services
                                                </label>
                                        <div className="col-lg-9 col-xl-4">
                                            <select 
                                            className="form-control" 
                                            name="service_id" 
                                            id="service_id"
                                            value={formik.values.service_id} 
                                            onChange={dropDownChange}  
                                            {...formik.getFieldProps("service_id")} 
                                            >
                                                <option value="">Select</option>
                                                <option value="1">Chat</option>
                                                <option value="2">Audio</option>
                                                <option value="3">Video</option>
                                                <option value="4">Clinic</option>
                                            </select>
                                            {formik.touched.service_id && formik.errors.service_id ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.service_id}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label text-lg-right">
                                            Consult Type:
                                                </label>
                                        <div className="col-lg-9 col-xl-4">
                                            <input 
                                            type="text" 
                                            className="form-control" 
                                            name="consultType" 
                                            id="consultType"
                                            value={formik.values.consultType}
                                            placeholder="Consult Type." 
                                            {...formik.getFieldProps("consultType")} />
                                            {formik.touched.consultType && formik.errors.consultType ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.consultType}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label text-lg-right">
                                            Enter Amount
                                                </label>
                                        <div className="col-lg-9 col-xl-4">
                                            <input 
                                            type="number" 
                                            className="form-control" 
                                            name="amount" 
                                            id="amount" 
                                            min="1"
                                            value={formik.values.amount} 
                                            placeholder="Amount." 
                                            {...formik.getFieldProps("amount")} 
                                            />
                                            {formik.touched.amount && formik.errors.amount ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.amount}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label text-lg-right">
                                            Appointment Type:
                                                </label>
                                        <div className="col-lg-9 col-xl-4">
                                            {/* <input type="text" className="form-control" name="appointment_type"  min="1" placeholder="Amount." />  */}
                                            <select 
                                            className="form-control" 
                                            name="appointmentType" 
                                            id="appointmentType"
                                            value={formik.values.appointmentType} 
                                            onChange={dropDownChange} 
                                            {...formik.getFieldProps("appointmentType")}
                                            >
                                                <option value="">Select</option>
                                                <option value="First Visit">First Visit</option>
                                                <option value="Routine">Routine</option>
                                                <option value="Follow-up">Follow-up</option>
                                                <option value="Procedure/Vaccination">Procedure/Vaccination</option>
                                                <option value="Dressing/Plaster">Dressing/Plaster</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            {formik.touched.appointmentType && formik.errors.appointmentType ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.appointmentType}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="row">
                                <div className="col-lg-3"></div>
                                <div className="col-lg-9">
                                    <button
                                        type="submit"
                                        className={`btn btn-primary font-weight-bold mr-2`}
                                    >
                                        <i className="la la-eye" /> Submit
                                            </button>
                                    {" "}
                                    <button
                                        type="reset"
                                        className={`btn btn-clean font-weight-bold mr-2`}
                                    >
                                        <i className="la la-recycle" /> Reset
                                            </button>
                                    {" "}
                                    <span
                                        className={`ml-3 ${clsx({
                                            spinner: isLoading
                                        })}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
