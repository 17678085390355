import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postPatientRegistration, getPatientDetails, postPatientUpdate } from "../service/PatientService";
import swal from 'sweetalert';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const initialValues = {
    patientName: "",
    email: "",
    phone: "",
    address: "",
    gender: "",
    bloodgroup: "",
    birthdate: "",
    token: ""
};

export function PatientRegistration(props) {
    const [loading, setLoading] = useState(false);
    const { authToken } = useSelector(state => state.auth);
    initialValues.token = authToken;
    const [page, setPage] = useState(1);
    const [state, setState] = React.useState({
        action: "CREATE"
    });
    let history = useHistory();
    const PatientRegistrationSchema = Yup.object().shape({
        patientName: Yup.string()
            .required("Name is mandatory"),
        email: Yup.string()
            .email("Enter valid email")
            .required("Email is mandatory"),
        phone: Yup.string()
            .required("Mobile is mandatory"),
        address: Yup.string()
            .required("Address is mandatory"),
        gender: Yup.string()
            .required("Gender is mandatory"),
        bloodgroup: Yup.string()
            .required("Blood Group is mandatory"),
        birthdate: Yup.string()
            .required("Birth Date is mandatory"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };


    useEffect(() => {
        if (history.location.state != null && history.location.state.id > 0) {
            getPatientDetails(history.location.state.id)
                .then(
                    (response) => {
                        initialValues.patientName = response.data.data.name;
                        initialValues.email = response.data.data.email;
                        initialValues.phone = response.data.data.phone;
                        initialValues.address = response.data.data.address;
                        initialValues.gender = response.data.data.gender;
                        initialValues.bloodgroup = response.data.data.bloodgroup;
                        initialValues.birthdate = response.data.data.birthdate;
                        initialValues["patient_id"] = response.data.data.id;
                        
                        setState(prevState => ({ ...prevState, action: "UPDATE" }));
                    }
                )
                .catch(
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }, [page]);

    const formik = useFormik({
        initialValues,
        validationSchema: PatientRegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            if(state.action === "CREATE"){
                postPatientRegistration(values)
                .then(
                    (response) => {
                        if (response.data.status == 200) {
                            swal({
                                title: "Done!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            });
                        }
                        else if (response.data.status == 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                    }
                )
                .catch(
                    (error) => {
                        disableLoading();
                        setSubmitting(false);
                        console.log(error)
                    }
                );
            }else{
                postPatientUpdate(values)
                .then(
                    (response) => {
                        if (response.data.status == 200) {
                            swal({
                                title: "Done!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            });
                        }
                        else if (response.data.status == 400) {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "warning",
                                button: "Ok!",
                                dangerMode: true,
                            });
                        }
                        disableLoading();
                    }
                )
                .catch(
                    (error) => {
                        disableLoading();
                        setSubmitting(false);
                        console.log(error)
                    }
                );
            }
        },
        onReset: () => {
            formik.values = initialValues;
        }
    });

    const dropDownChange = (event) => {
        formik.values[event.target.name] = event.target.value;
    }

    return (
        <div className="form" style={{ display: "block" }}>
            <form
                name="patientRegistrationForm"
                id="patientRegistrationForm"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
            >
                {/* begin: Alert */}
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}
                <div className="card-body">
                    <div className="tab-content pt-3">
                        <div className="tab-pane active">
                            {/* begin: Patient Name */}
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-lg-right">
                                    Patient Name:
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                    <input
                                        placeholder="Patient Name"
                                        type="text"
                                        className="form-control"
                                        name="patientName"
                                        id="patientName"
                                        {...formik.getFieldProps("patientName")}
                                    />
                                    {formik.touched.patientName && formik.errors.patientName ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.patientName}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* end: Name */}

                            {/* begin: Email */}
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-lg-right">
                                    Patient Email:
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                    <input
                                        placeholder="Patient Email"
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        {...formik.getFieldProps("email")}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.email}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* end: Email */}
                            {/* begin: Phone */}
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-lg-right">
                                    Patient Mobile:
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                    <input
                                        placeholder="Patient mobile number"
                                        type="text"
                                        className="form-control"
                                        name="phone"
                                        id="phone"
                                        {...formik.getFieldProps("phone")}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.phone}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* end: phone */}

                            {/* begin: address */}
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-lg-right">
                                    Patient Address:
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                    <textarea
                                        placeholder="Patient Address"
                                        type="text"
                                        className="form-control"
                                        name="address"
                                        id="address"
                                        {...formik.getFieldProps("address")}
                                    />
                                    {formik.touched.address && formik.errors.address ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.address}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* end: address */}
                            {/* begin: gender */}
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-lg-right">
                                    Gender:
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                    <select
                                        className="form-control"
                                        name="gender"
                                        id="gender"
                                        // value={formik.values.gender}
                                        onChange={dropDownChange}
                                        {...formik.getFieldProps("gender")}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                    </select>
                                    {formik.touched.gender && formik.errors.gender ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.gender}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* end: gender */}

                            {/* begin: blood group */}
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-lg-right">
                                    Blood Group:
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                    <select
                                        className="form-control"
                                        name="bloodgroup"
                                        id="bloodgroup"
                                        //value={formik.values.bloodgroup}
                                        onChange={dropDownChange}
                                        {...formik.getFieldProps("bloodgroup")}
                                    >
                                        <option value="">Select Blood Group</option>
                                        <option value="ANEGATIVE">A-</option>
                                        <option value="APOSITIVE">A+</option>
                                        <option value="BNEGATIVE">B-</option>
                                        <option value="BPOSITIVE">B+</option>
                                        <option value="ABNEGATIVE">AB-</option>
                                        <option value="ABPOSITIVE">AB+</option>
                                        <option value="ONEGATIVE">O-</option>
                                        <option value="OPOSITIVE">O+</option>
                                    </select>
                                    {formik.touched.bloodgroup && formik.errors.bloodgroup ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.bloodgroup}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* end: blood group */}

                            {/* begin: Birth Date */}
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-lg-right">
                                    Birth Date:
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                    <input
                                        placeholder="Birth Date"
                                        type="date"
                                        className="form-control"
                                        name="birthdate"
                                        id="birthdate"
                                        {...formik.getFieldProps("birthdate")}
                                    />
                                    {formik.touched.birthdate && formik.errors.birthdate ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.birthdate}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* end: Birth Date */}
                            {/* end: Age */}

                            {/* begin: Description */}
                            {/* <div className="form-group fv-plugins-icon-container col-md-6">
                        <textarea
                            placeholder="Description"
                            type="description"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "description"
                            )}`}
                            name="description"
                            id="description"
                            {...formik.getFieldProps("description")}
                        />
                        {formik.touched.description && formik.errors.description ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.description}
                                </div>
                            </div>
                        ) : null}
                    </div> */}
                            {/* end: Description */}
                        </div>
                        {/* <div className="row col-md-12"> */}
                        {/* begin: Password */}
                        {/* <div className="form-group fv-plugins-icon-container col-md-6">
                        <input
                            placeholder="Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                        ) : null}
                    </div> */}
                        {/* end: Password */}

                        {/* begin: Confirm Password */}
                        {/* <div className="form-group fv-plugins-icon-container col-md-6">
                        <input
                            placeholder="Confirm Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "changepassword"
                            )}`}
                            name="changepassword"
                            {...formik.getFieldProps("changepassword")}
                        />
                        {formik.touched.changepassword && formik.errors.changepassword ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.changepassword}
                                </div>
                            </div>
                        ) : null}
                    </div> */}
                        {/* end: Confirm Password */}
                        {/* </div> */}
                        {/* <div className="row"> */}
                        {/* begin: Terms and Conditions */}
                        {/* <div className="form-group fv-plugins-icon-container py-5 px-8 col-md-6">
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                name="acceptTerms"
                                {...formik.getFieldProps("acceptTerms")}
                            />
            I agree the{" "}
                            <Link to="/terms" target="_blank" rel="noopener noreferrer">
                                Terms & Conditions
            </Link>
            .
            <span />
                        </label>
                        {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                            </div>
                        ) : null}
                    </div> */}
                        {/* end: Terms and Conditions */}
                        {/* </div> */}
                        <div className="form-group d-flex flex-wrap flex-center">
                            <button
                                type="submit"
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                <span>Submit</span>
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </button>
                            <button
                                type="reset"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div >
    );
}


