/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {/* <li
          className={`menu-item menu-item-submenu  ${getMenuItemActive(
            "/builder"
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Doctor</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/builder")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/builder">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Add New Doctor</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(
                "/appointment"
              )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/appointment">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Doctor List</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        <li
          className={`menu-item menu-item-submenu  ${getMenuItemActive("/patient")}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/patient">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Patient</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/patient")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/patient">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Add New Patient</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive(
                "/patient"
              )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/patients">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Patient List</span>
                </NavLink>
          {/* <Route path="/patients" component={AsideMenuList} /> */}
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu  ${getMenuItemActive(
            "/appointment"
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/appointment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Appointment</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/appointment")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/appointment">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Add New Appointment</span>
                </NavLink>
              </li>


              <li className={`menu-item ${getMenuItemActive(
                "/appointments"
              )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/appointments">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Appointment List</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/referedpatients")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/referedpatients">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Refered Patient</span>
          </NavLink>
        </li>


        {/* <li
          className={`menu-item ${getMenuItemActive("/commision_master")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/commision_master">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Commision Charges </span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item ${getMenuItemActive("/feedback_review")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/feedback_review">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Feedback and Reviews</span>
          </NavLink>
        </li> */}


        {/*end::1 Level*/}


        {/* Components */}
        {/* begin::section */}
        {/* <li className="menu-section ">
            <h4 className="menu-text">Other</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
        {/* end:: section */}

        {/* Material-UI */}
        {/*begin::1 Level*/}
        {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material"
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          > */}
        {/* <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">Material UI</span>
              <i className="menu-arrow"/>
            </NavLink> */}
        {/* <div className="menu-submenu "> */}
        {/* <i className="menu-arrow"/> */}
        {/* <ul className="menu-subnav"> */}
        {/* <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Material UI</span>
                </span>
                </li> */}

        {/* Inputs */}
        {/*begin::2 Level*/}
        {/* <li
                    className={`menu-item menu-item-submenu ${
                        "/google-material/inputs"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                > */}
        {/* <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Inputs</span>
                    <i className="menu-arrow"/>
                  </NavLink> */}
        {/* <div className="menu-submenu "> */}
        {/* <i className="menu-arrow"/> */}
        {/* <ul className="menu-subnav"> */}
        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item  ${getMenuItemActive(
                              "/google-material/inputs/autocomplete"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                              to="/google-material/inputs/autocomplete">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Autocomplete</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/inputs/buttons"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/inputs/buttons">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Buttons</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/inputs/checkboxes"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/inputs/checkboxes">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Checkboxes</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/inputs/pickers"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/inputs/pickers">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Pickers</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/inputs/radio-buttons"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/inputs/radio-buttons">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Radio Buttons</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/inputs/selects"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/inputs/selects">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Selects</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/inputs/switches"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/inputs/switches">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Switches</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/inputs/text-fields"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/inputs/text-fields">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Text Fields</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/inputs/transfer-list"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/inputs/transfer-list">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Transfer List</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}
        {/* </ul> */}
        {/* </div> */}
        {/* </li> */}
        {/*end::2 Level*/}

        {/* Navigation */}
        {/*begin::2 Level*/}
        {/* <li
                    className={`menu-item menu-item-submenu  ${getMenuItemActive(
                        "/google-material/navigation"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                > */}
        {/* <NavLink className="menu-link menu-toggle" to="/google-material/navigation">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Navigation</span>
                    <i className="menu-arrow"/>
                  </NavLink> */}
        {/* <div className="menu-submenu"> */}
        {/* <i className="menu-arrow"/> */}
        {/* <ul className="menu-subnav"> */}
        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/navigation/bottom-navigation"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/navigation/bottom-navigation">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Bottom Navigation</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/navigation/breadcrumbs"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/navigation/breadcrumbs">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Breadcrumbs</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/navigation/drawern"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/navigation/drawer">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Drawers</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/navigation/links"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/navigation/links">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">NavLinks</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/navigation/menus"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/navigation/menus">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Menus</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/navigation/steppers"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/navigation/steppers">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Steppers</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/navigation/tabs"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/navigation/tabs">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Tabs</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}
        {/* </ul> */}
        {/* </div> */}
        {/* </li> */}
        {/*end::2 Level*/}

        {/* Surfaces */}
        {/*begin::2 Level*/}
        {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/surfaces"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                > */}
        {/* <NavLink className="menu-link menu-toggle" to="/google-material/surfaces">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Surfaces</span>
                    <i className="menu-arrow"/>
                  </NavLink> */}
        {/* <div className="menu-submenu"> */}
        <i className="menu-arrow" />
        {/* <ul className="menu-subnav"> */}
        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/surfaces/app-bar"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/surfaces/app-bar">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">App Bar</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/surfaces/paper"
                          )}`}
                          aria-haspopup="true"
                      > */}
        {/* <NavLink className="menu-link" to="/google-material/surfaces/paper">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Paper</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/surfaces/cards"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/surfaces/cards">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Cards</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/surfaces/expansion-panels"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/surfaces/expansion-panels">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Expansion Panels</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}
        {/* </ul> */}
        {/* </div> */}
        {/* </li> */}
        {/*end::2 Level*/}

        {/* Feedback */}
        {/*begin::2 Level*/}
        {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/feedback"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/feedback">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Feedback</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                      {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/feedback/progress"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/feedback/progress">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Progress</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/feedback/dialogs"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/feedback/dialogs">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Dialogs</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/feedback/snackbars"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/feedback/snackbars">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Snackbars</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}
        {/* </ul> */}
        {/* </div> */}
        {/* </li> */}
        {/*end::2 Level*/}

        {/* Data Display */}
        {/*begin::2 Level*/}
        {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/data-displays"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/data-displays">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Data Display</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                      {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/avatars"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/avatars">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Avatars</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/badges"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/badges">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Badges</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/chips"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/chips">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Chips</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/dividers"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/dividers">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Dividers</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/icons"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/icons">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Icons</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/lists"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/lists">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Lists</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/tables"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/tables">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Tables</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/tooltips"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/tooltips">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Tooltips</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/data-displays/typography"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/data-displays/typography">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Typography</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}
        {/* </ul> */}
        {/* </div> */}
        {/* </li> */}
        {/*end::2 Level*/}

        {/* Utils */}
        {/*begin::2 Level*/}
        {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/utils"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/utils">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Utils</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                      {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/utils/click-away-listener"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/utils/click-away-listener">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Click Away Listener</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/utils/no-ssr"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/utils/no-ssr">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">No SSR</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/utils/popover"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/utils/popover">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Popover</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/utils/popper"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/utils/popper">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Popper</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/utils/portal"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/utils/portal">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Portal</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/utils/transitions"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/utils/transitions">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Transitions</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/utils/use-media-query"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/utils/use-media-query">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">useMediaQuery</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}
        {/* </ul>
                  </div>
                </li>   */}
        {/*end::2 Level*/}

        {/* Layout */}
        {/*begin::2 Level*/}
        {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/layout"
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/layout">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Layout</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                      {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/layout/box"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/layout/box">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Box</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/layout/container"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/layout/container">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Container</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/layout/grid"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/layout/grid">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Grid</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/layout/grid-list"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/layout/grid-list">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Grid list</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}

        {/*begin::3 Level*/}
        {/* <li
                          className={`menu-item ${getMenuItemActive(
                              "/google-material/layout/hidden"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/google-material/layout/hidden">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Hidden</span>
                        </NavLink>
                      </li> */}
        {/*end::3 Level*/}
        {/* </ul> */}
        {/* </div> */}
        {/* </li>   */}
        {/*end::2 Level*/}
        {/* </ul> */}
        {/* </div> */}
        {/* </li> */}
        {/*end::1 Level*/}

        {/* Bootstrap */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}

        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}

        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
