/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, {useMemo, useState} from "react";
import {Formik} from "formik";
import {get, merge} from "lodash";
import {FormHelperText, Switch} from "@material-ui/core";
import clsx from "clsx";
import axios from 'axios';
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import swal from 'sweetalert';
import {coy as highlightStyle} from "react-syntax-highlighter/dist/esm/styles/prism";
import {useHtmlClassService, setLayoutConfig, getInitLayoutConfig} from "../../layout";
import {Card, CardBody, CardHeader} from "../controls";
import {Table} from "react-bootstrap";
 import {Notice, KTCodeExample} from "../../../_metronic/_partials/controls";

const localStorageActiveTabKey = "appointmentActiveTab";

export function Commision_master() {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const htmlClassService = useHtmlClassService();
    const initialValues = useMemo(
        () =>
            merge(
                // Fulfill changeable fields.
                getInitLayoutConfig(),
                htmlClassService.config
            ),
        [htmlClassService.config]
    );

    const APP_URL = 'http://localhost:3000/admin/Authentication/';     

    const saveCurrentTab = (_tab) => {
        localStorage.setItem(localStorageActiveTabKey, _tab);
    }

            
	// this.state = {                       
    //     fields: {
    //         name:'',
    //     },
    //     errors: {},
    //     userdob: new Date(),
    //     addMsg:'',
    //     hStyles:{},
    //     doctor_list:[],
    //     patient_list:[],
    //     selectedIndex:'',
    //     checked:{},
    // }

    // const obj = {
        
    //     firstname: this.state.fields.firstname,
    //     lastname: this.state.fields.lastname,
    //     userdob: this.state.userdob,
    //     useremail: this.state.useremail,
    //     usercontact: this.state.usercontact,
    //     notification: this.state.fields.notification,
    //     useraddr1:this.state.fields.useraddr1,
    //     useraddr2:this.state.fields.useraddr2,
    //     txtmsg: this.state.fields.txtmsg,
    //     txtemail:this.state.fields.txtemail,
    //     country:this.state.selectedIndex,
    //     city:this.state.fields.city,
    //     state:this.state.fields.state,
    //     pincode:this.state.fields.pincode,
    //     username:this.state.fields.username,
    //     password:this.state.fields.password,
    //     confirmpassword:this.state.fields.confirmpassword,
    //     time_zone:this.state.fields.time_zone,
    //     odds_display:this.state.fields.odds_display,

    // };

      /*Register API*/
             
//         const API_PATH = 'http://103.250.185.131/~drapi/Authentication/registerDoctor';

//       axios.post(API_PATH, obj)
//          .then(
//            (res) => {
//             // console.log(res.message);
//                     if (res.data.status == 200) {
//                       //hrow new Error("Bad response from server");

//                         swal({
//                             title: "Done!",
//                             text: "User is successfully added!",
//                             icon: "success",
//                             button: "Ok!",
//                           });

//                         this.setState({
//                             fields: ''
//                           });
//                              // console.log(res.data.message);
//                     }
//                     else if(res.data.status == 400)
//                     {
//                             swal({
//                                     title: "Error!",
//                                     text: res.data.message,
//                                     icon: "warning",
//                                     button: "Ok!",
//                                     dangerMode: true,
//                             });
//                          // console.log(res.data.message);     
//                     }
//                     // return res.json();
//                   }
//           );
         

//     //Doctor list 
//    axios.get(APP_URL + 'getDoctor/')
//         .then(res => {
//             // console.log(res.data.status); 
//         if (res.data.status === 200) {
                
//               this.setState({doctor_list: res.data.data})
//                // console.log(res.data.data[0]);
//         }
//         else if(res.data.status === 201)
//         {
          
//             this.setState({doctor_list: res.data.data });
//              // console.log(res.data.message);     
//         }


//         })

//          //Patient list 
//     axios.get(APP_URL + 'patientList/')
//          .then(res => {
//              // console.log(res.data.status); 
//          if (res.data.status === 200) {
                 
//                this.setState({patient_list: res.data.data})
//                 // console.log(res.data.data[0]);
//          }
//          else if(res.data.status === 201)
//          {
           
//              this.setState({patient_list: res.data.data });
//               // console.log(res.data.message);     
//          }
  
//         })


//     //appointment list 
//    axios.get(APP_URL + 'appointment/')
//         .then(res => {
//             // console.log(res.data.status); 
//         if (res.data.status === 200) {
                
//               this.setState({doctor_list: res.data.data})
//                // console.log(res.data.data[0]);
//         }
//         else if(res.data.status === 201)
//         {
          
//             this.setState({doctor_list: res.data.data });
//              // console.log(res.data.message);     
//         }



    return (
        <>
          
            <Formik
                initialValues={initialValues}
                onSubmit={values => {
                    setIsLoading(true);
                    setLayoutConfig(values);
                }}
                onReset={() => {
                    setIsLoading(true);
                    setLayoutConfig(getInitLayoutConfig());
                }}
            >
                {({values, handleReset, handleSubmit, handleChange, handleBlur}) => (
                    <>
                        <div className="card card-custom">
                            {/*Header*/}
                            <div className="card-header card-header-tabs-line">
                                <ul
                                    className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                                    data-remember-tab="tab_id"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${key === 0 ? "active" : ""}`}
                                            data-toggle="tab"
                                            onClick={() => {
                                                setKey(0);
                                                saveCurrentTab(0);
                                            }}
                                        >
                                            Add New Commission
                                        </a>
                                    </li>
                                      
                                </ul>
                            </div>

                            <div className="form">
                            <form action="" method="POST" autoComplete="off"  name="userRegistrationForm" id="affiliateSignup" >
                                <div className="card-body">
                                    <div className="tab-content pt-3">
                                        <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Select Doctor:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <select
                                                        className="form-control"
                                                        name="subheader.width"
                                                        onBlur={handleBlur}
                                                        value={get(values, "subheader.width")}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select</option>
                                                      
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Set Commission Charges:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                     <input type="text" className="form-control" name="commission"  maxLength="50" placeholder="Enter commission charges" />
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`tab-pane ${key === 1 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Date Of Birth:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                        <input type="text" className="form-control" name="birthdate"  maxLength="50" placeholder="Date Of Birth." /> 
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Fixed Subheader:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <Switch
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="subheader.fixed"
                                                        checked={!!get(values, "subheader.fixed")}
                                                    />
                                                    <FormHelperText>
                                                        Enable fixed(sticky) subheader. Requires{" "}
                                                        <code>Solid</code> subheader style.
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Width:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <select
                                                        className="form-control"
                                                        name="subheader.width"
                                                        onBlur={handleBlur}
                                                        value={get(values, "subheader.width")}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="fluid">Fluid</option>
                                                        <option value="fixed">Fixed</option>
                                                    </select>
                                                    <FormHelperText>
                                                        Select layout width type
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Subheader Style:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <select
                                                        className="form-control"
                                                        name="subheader.style"
                                                        onBlur={handleBlur}
                                                        value={get(values, "subheader.style")}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="transparent">Transparent</option>
                                                        <option value="solid">Solid</option>
                                                    </select>
                                                    <FormHelperText>
                                                        Select subheader style
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`tab-pane ${key === 2 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Width:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <select
                                                        className="form-control"
                                                        name="content.width"
                                                        onBlur={handleBlur}
                                                        value={get(values, "content.width")}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="fluid">Fluid</option>
                                                        <option value="fixed">Fixed</option>
                                                    </select>
                                                    <FormHelperText>
                                                        Select layout width type
                                                    </FormHelperText>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${key === 3 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Display:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <Switch
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="aside.self.display"
                                                        checked={!!get(values, "aside.self.display")}
                                                    />
                                                    <FormHelperText>Display aside</FormHelperText>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Fixed:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <Switch
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="aside.self.fixed"
                                                        checked={!!get(values, "aside.self.fixed")}
                                                    />
                                                    <FormHelperText>
                                                        Set fixed aside layout
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Minimize:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <Switch
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="aside.self.minimize.toggle"
                                                        checked={
                                                            !!get(values, "aside.self.minimize.toggle")
                                                        }
                                                    />
                                                    <FormHelperText>
                                                        Allow aside minimizing
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Hoverable:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <Switch
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="aside.self.minimize.hoverable"
                                                        checked={
                                                            !!get(values, "aside.self.minimize.hoverable")
                                                        }
                                                    />
                                                    <FormHelperText>
                                                        Expand Minimized Aside on hover
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Default Minimize:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <Switch
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="aside.self.minimize.default"
                                                        checked={
                                                            !!get(values, "aside.self.minimize.default")
                                                        }
                                                    />
                                                    <FormHelperText>
                                                        Set aside minimized by default
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label
                                                    className="col-lg-3 col-form-label pt-4 text-lg-right">
                                                    Submenu Toggle:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <Switch
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="aside.menu.dropdown"
                                                        checked={!!get(values, "aside.menu.dropdown")}
                                                    />
                                                    <FormHelperText>
                                                        Select submenu toggle mode (works only when{" "}
                                                        <code>Fixed Mode</code> is disabled)
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`tab-pane ${key === 4 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Fixed Desktop Footer:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <Switch
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name="footer.fixed"
                                                        checked={!!get(values, "footer.fixed")}
                                                    />

                                                    <FormHelperText>Set fixed desktop footer</FormHelperText>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Width:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <select
                                                        className="form-control form-control-solid"
                                                        name="footer.self.width"
                                                        onBlur={handleBlur}
                                                        value={get(values, "footer.self.width")}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="fluid">Fluid</option>
                                                        <option value="fixed">Fixed</option>
                                                    </select>
                                                    <FormHelperText>
                                                        Select layout width type
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                               </form>                             
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-9">
                                            <button
                                                type="button"
                                                onClick={handleSubmit}
                                                className={`btn btn-primary font-weight-bold mr-2`}
                                            >
                                                <i className="la la-eye"/> Submit
                                            </button>
                                            {" "}
                                            <button
                                                type="button"
                                                onClick={handleReset}
                                                className={`btn btn-clean font-weight-bold mr-2`}
                                            >
                                                <i className="la la-recycle"/> Reset
                                            </button>
                                            {" "}
                                            <span
                                                className={`ml-3 ${clsx({
                                                    spinner: isLoading
                                                })}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Config*/}
                        
                    </>
                )}
            </Formik>
        </>
    );
}
