import CentralService from '../../../common/service/CentralService';
import ApiConstants from '../../../common/constants/ApiConstants';

const centralService = new CentralService();

export function postAppointmentBook(data){
    return centralService.doPost(ApiConstants.registerAppointmentPath,data);
}

export function getAppointmentList(){
    return centralService.doGet(ApiConstants.loadAppointmentListPath);
}

export function getAppointmentDetails(params){
    return centralService.doGet(ApiConstants.loadAppointmentDetailsPath + "/" + params);
}

export function postAppointmentUpdate(data){
    return centralService.doPost(ApiConstants.postAppointmentUpdatePath,data);
}