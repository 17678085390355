/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, {useMemo, useState} from "react";
import {Formik} from "formik";
import {get, merge} from "lodash";
import {FormHelperText, Switch} from "@material-ui/core";
import clsx from "clsx";
import axios from 'axios';
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import swal from 'sweetalert';
import {coy as highlightStyle} from "react-syntax-highlighter/dist/esm/styles/prism";
import {useHtmlClassService, setLayoutConfig, getInitLayoutConfig} from "../../layout";
import {Card, CardBody, CardHeader} from "../controls";
import {Table} from "react-bootstrap";
 import {Notice, KTCodeExample} from "../../../_metronic/_partials/controls";

const localStorageActiveTabKey = "builderActiveTab";

export function Builder() {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const htmlClassService = useHtmlClassService();
    const initialValues = useMemo(
        () =>
            merge(
                // Fulfill changeable fields.
                getInitLayoutConfig(),
                htmlClassService.config
            ),
        [htmlClassService.config]

    );


    const validate = values => {
        const errors = {};
        if (!values.username) {
          errors.username = 'Required';
        } else if (values.firstName.length > 25) {
          errors.username = 'Must be 25 characters or less';
        }
      
        if (!values.address) {
          errors.address = 'Required';
        } else if (values.lastName.length > 200) {
          errors.address = 'Must be 200 characters or less';
        }
        
        if (!values.phone) {
            errors.phone = 'Required';
          } else if (!/[0-9 -()+]+$/i.test(values.phone)) {
            errors.phone = 'Must be 10 characters or less';
          }

        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
      
        return errors;
      };


    const formik = Formik({
        initialValues: {
            username: '',
            email:'',
            address:'',
            phone:'',
            profile:'',
            gender:'',
            birthdate:''

        },
        onSubmit: values => {
        
             /*Register API*/
             
            const API_PATH = 'http://103.250.185.131/~drapi/Authentication/registerDoctor';

         // alert(JSON.stringify(values, null, 2));

            
      axios.post(API_PATH, formik)
      .then(
        (res) => {
         // console.log(res.message);
                 if (res.data.status == 200) {
                   //hrow new Error("Bad response from server");

                     swal({
                         title: "Done!",
                         text: "User is successfully added!",
                         icon: "success",
                         button: "Ok!",
                       });

                     this.setState({
                         fields: ''
                       });
                          // console.log(res.data.message);
                 }
                 else if(res.data.status == 400)
                 {
                         swal({
                                 title: "Error!",
                                 text: res.data.message,
                                 icon: "warning",
                                 button: "Ok!",
                                 dangerMode: true,
                         });
                      // console.log(res.data.message);     
                 }
                 // return res.json();
               }
       );

        },
      });


    const APP_URL = 'http://localhost:3000/admin/Authentication/';     

    const saveCurrentTab = (_tab) => {
        localStorage.setItem(localStorageActiveTabKey, _tab);
    }
            
	// this.state = {                       
    //     fields: {
    //         name:'',
    //     },
    //     errors: {},
    //     userdob: new Date(),
    //     addMsg:'',
    //     hStyles:{},
    //     doctor_list:[],
    //     patient_list:[],
    //     selectedIndex:'',
    //     checked:{},
    // }

    // const obj = {
        
    //     firstname: this.state.fields.firstname,
    //     lastname: this.state.fields.lastname,
    //     userdob: this.state.userdob,
    //     useremail: this.state.useremail,
    //     usercontact: this.state.usercontact,
    //     notification: this.state.fields.notification,
    //     useraddr1:this.state.fields.useraddr1,
    //     useraddr2:this.state.fields.useraddr2,
    //     txtmsg: this.state.fields.txtmsg,
    //     txtemail:this.state.fields.txtemail,
    //     country:this.state.selectedIndex,
    //     city:this.state.fields.city,
    //     state:this.state.fields.state,
    //     pincode:this.state.fields.pincode,
    //     username:this.state.fields.username,
    //     password:this.state.fields.password,
    //     confirmpassword:this.state.fields.confirmpassword,
    //     time_zone:this.state.fields.time_zone,
    //     odds_display:this.state.fields.odds_display,

    // };

      /*Register API*/
             
//         const API_PATH = 'http://103.250.185.131/~drapi/Authentication/registerDoctor';

//       axios.post(API_PATH, obj)
//          .then(
//            (res) => {
//             // console.log(res.message);
//                     if (res.data.status == 200) {
//                       //hrow new Error("Bad response from server");

//                         swal({
//                             title: "Done!",
//                             text: "User is successfully added!",
//                             icon: "success",
//                             button: "Ok!",
//                           });

//                         this.setState({
//                             fields: ''
//                           });
//                              // console.log(res.data.message);
//                     }
//                     else if(res.data.status == 400)
//                     {
//                             swal({
//                                     title: "Error!",
//                                     text: res.data.message,
//                                     icon: "warning",
//                                     button: "Ok!",
//                                     dangerMode: true,
//                             });
//                          // console.log(res.data.message);     
//                     }
//                     // return res.json();
//                   }
//           );
         

//     //Doctor list 
//    axios.get(APP_URL + 'getDoctor/')
//         .then(res => {
//             // console.log(res.data.status); 
//         if (res.data.status === 200) {
                
//               this.setState({doctor_list: res.data.data})
//                // console.log(res.data.data[0]);
//         }
//         else if(res.data.status === 201)
//         {
          
//             this.setState({doctor_list: res.data.data });
//              // console.log(res.data.message);     
//         }


//         })

//          //Patient list 
//     axios.get(APP_URL + 'patientList/')
//          .then(res => {
//              // console.log(res.data.status); 
//          if (res.data.status === 200) {
                 
//                this.setState({patient_list: res.data.data})
//                 // console.log(res.data.data[0]);
//          }
//          else if(res.data.status === 201)
//          {
           
//              this.setState({patient_list: res.data.data });
//               // console.log(res.data.message);     
//          }
  
//         })


    return (
        // <>
          
        //     <Formik
        //         initialValues={initialValues}
        //         onSubmit={values => {
        //             setIsLoading(true);
        //             setLayoutConfig(values);

        //            // alert(JSON.stringify(values, null, 2));
        //         }}
        //         onReset={() => {
        //             setIsLoading(true);
        //             setLayoutConfig(getInitLayoutConfig());
        //         }}
        //     >
               
                        <div className="card card-custom">
                            {/*Header*/}
                            <div className="card-header card-header-tabs-line">
                                <ul
                                    className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                                    data-remember-tab="tab_id"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${key === 0 ? "active" : ""}`}
                                            data-toggle="tab"
                                            onClick={() => {
                                                setKey(0);
                                                saveCurrentTab(0);
                                            }}
                                        >
                                            Add New Doctor
                                        </a>
                                    </li>
                                      
                                </ul>
                            </div>

                            <div className="form">
                            <form action="" method="POST" autoComplete="off" onSubmit={Formik.handleSubmit}  name="userRegistrationForm" id="affiliateSignup" >
                                <div className="card-body">
                                    <div className="tab-content pt-3">
                                        <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Name:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                     <input type="text" className="form-control" name="username"  maxLength="50" placeholder="Enter Full Name" />
                                                
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Email:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                     <input type="text" className="form-control" name="email"  maxLength="50" placeholder="Enter Email" />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Address:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                    <input type="text" className="form-control" name="address"  maxLength="50" placeholder="Enter Address" />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Mobile No:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                       <input type="text" className="form-control" name="phone"  maxLength="50" placeholder="Enter Mobile No." /> 
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Specialization:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                         <input type="text" className="form-control" name="specialization" id="specialization" maxLength="50" placeholder="Enter Specialization." /> 
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                   Qualification:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                <input type="text" className="form-control" name="qualification" id="qualification" maxLength="50" placeholder="Enter Qualification." />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${key === 1 ? "active" : ""}`}>
                                            <div className="form-group row">
                                                <label className="col-lg-3 col-form-label text-lg-right">
                                                    Focus Area:
                                                </label>
                                                <div className="col-lg-9 col-xl-4">
                                                        <input type="text" className="form-control" name="focus_area" id="focus_area" placeholder="Enter Focus Area." /> 
                                                </div>
                                            </div>

                                              

                                        </div>
                                          
                                    </div>
                                </div>
                               </form>                             
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-9">
                                            <button
                                                type="submit"
                                                // onClick={handleSubmit}
                                                className={`btn btn-primary font-weight-bold mr-2`}
                                            >
                                                <i className="la la-eye"/> Submit
                                            </button>
                                            {" "}
                                            <button
                                                type="button"
                                                // onClick={handleReset}
                                                className={`btn btn-clean font-weight-bold mr-2`}
                                            >
                                                <i className="la la-recycle"/> Reset
                                            </button>
                                            {" "}
                                            <span
                                                className={`ml-3 ${clsx({
                                                    spinner: isLoading
                                                })}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

    );
}
