/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
import MaterialTable from 'material-table'
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { getAppointmentList } from "../service/AppointmentService";
import { useHistory } from "react-router-dom";

const localStorageActiveTabKey = "appointmentActiveTab";

export const AppointmentList = () => {
    const activeTab = localStorage.getItem("authToken");
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    let history = useHistory();
    const enableLoading = () => {
        setLoading(true);
    };
    const disableLoading = () => {
        setLoading(false);
    };

    const OnRowClickEvent = (event, rowData) => {
        history.push("/appointmentupdate", { id: rowData.apt_id });
    }

    const [state, setState] = React.useState({
        columns: [
            { title: 'Patient Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Mobile Number', field: 'phone' },
            { title: 'Gender', field: 'gender' },
            { title: 'Service', field: 'service_name' },
            { title: 'Payment', field: 'paymentStatus' },
            { title: 'Consult Type', field: 'consultType' },
            { title: 'Appointment Type', field: 'appointmentType' },
            { title: 'Appointment Date', field: 'date' }
        ],
        data: [
        ]
    });
    useEffect(() => {
        getAppointmentList()
            .then(
                (response) => {
                    if (response.data.status === 200) {
                        setState(prevState => ({ ...prevState, data: response.data.data }));
                    }
                    else if (response.data.status === 400) {
                        Swal.fire({
                            title: "Error!",
                            text: response.data.message,
                            icon: "warning",
                            button: "Ok!",
                            dangerMode: true
                        });
                    }
                    disableLoading();
                }
            )
            .catch(
                (error) => {
                    console.log(error)
                }
            );
    }, [page]);

    return (
        <MaterialTable
            title="Appointment List (Clinic Only)"
            columns={state.columns}
            data={state.data}
            onRowClick={OnRowClickEvent}
        />
    );
}
