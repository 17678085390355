import CentralService from '../../../common/service/CentralService';
import ApiConstants from '../../../common/constants/ApiConstants';

const centralService = new CentralService();

export function validateDoctorLogin(data){
    return centralService.doPost(ApiConstants.loginDoctorPath,data);
}

export function getUserToken(){
}