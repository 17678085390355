import React from "react";
import { Patient } from "../../_metronic/_partials";
import { PatientRegistration, PatientList, PatientReferedList, PatientRegistrationUpdate } from "../../app/modules/Patient";

export function PatientPage() {
    return <Patient />;
}

export function PatientRegistrationPage() {
    return <PatientRegistration />;
}

export function PatientListPage() {
    return <PatientList />;
}

export function ReferedPatientListPage() {
    return <PatientReferedList />;
}

export function PatientRegistrationUpdatePage() {
    return <PatientRegistrationUpdate />;
}
